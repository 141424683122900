import React, { useRef, useState } from "react"
import { useMeasure } from "../utils/use-measure"
import { motion } from "framer-motion"
import { Pager } from "./pager"
import { Box, Grid, Link, Flex, Text, Container, Heading } from "theme-ui"
import { i18nContext } from "../context/i18nContext"
import { InboundLink, OutboundLink } from "./link"
import { getColor } from "@theme-ui/color"
import themeUiTheme from "../gatsby-plugin-theme-ui"
import styled from "styled-components"

const primary = getColor(themeUiTheme, "primary")

function translateItem({ x, y }) {
  return `translateX(${x}) translateY(${y})`
}

const Slider = styled(motion.div)`
  position: absolute;
  bottom: -1px;
  left: 0;
  right: 0;
  height: 4px;
  background: ${primary};
`

const Tabs = ({ documents }) => {
  const [value, setValue] = useState(0)
  const childRefs = useRef(new Map())
  const tabListRef = useRef()
  const [slider, setSlider] = useState({ left: 0, right: 0 })
  const { bounds, ref } = useMeasure()

  // measure our elements
  React.useEffect(() => {
    const target = childRefs.current.get(value)
    const container = tabListRef.current
    if (target) {
      const cRect = container.getBoundingClientRect()

      // when container is `display: none`, width === 0.
      // ignore this case
      if (cRect.width === 0) {
        return
      }

      const tRect = target.getBoundingClientRect()
      const left = tRect.left - cRect.left
      const right = cRect.right - tRect.right

      setSlider({
        hasValue: true,
        left: left + 8,
        right: right + 8,
      })
    }
  }, [value, bounds])

  return (
    <div>
      <Box
        sx={{
          overflowY: "hidden",
          boxShadow: "none",
        }}
        ref={ref}
      >
        <Box
          ref={tabListRef}
          sx={{
            display: "block",
            position: "relative",
            borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
          }}
        >
          {documents.map((document, index) => (
            <motion.button
              style={{
                border: "none",
                padding: "16px",
                backgroundColor: "transparent",
                position: "relative",
              }}
              key={document.title + index}
              isActive={index === value}
              transition={{ duration: 0.1 }}
              ref={el => childRefs.current.set(index, el)}
              onClick={() => setValue(index)}
            >
              <Text
                sx={{
                  "white-space": "nowrap",
                  "-webkit-appearance": "none",
                  "box-sizing": "border-box",
                  "text-align": "center",
                  "-webkit-font-smoothing": "antialiased",
                  "text-rendering": "optimizelegibility",
                  "user-select": "none",
                  outline: "none",
                  "-webkit-tap-highlight-color": "transparent",
                  "box-shadow": "none",
                  cursor: "pointer",
                  "text-decoration": "none",
                  "border-width": "initial",
                  "border-style": "none",
                  "border-color": "initial",
                  "border-image": "initial",
                  padding: "10px 1rem",
                  "text-size-adjust": "none",
                  "text-overflow": "ellipsis",
                  "line-height": 1.5,
                  "font-size": "0.875rem",
                  "font-weight": 600,
                  color: "text",
                  pl: [3],
                  overflow: "hidden",
                  position: "relative",
                  fontWeight: "500",
                  "&:before": {
                    content: "'•'",
                    fontSize: [4],
                    top: "50%",
                    transform: 'translateY(-50%)',
                    position: "absolute",
                    left: 0,
                    mr: "0.5em",
                    color: index === value ? "primary" : "text",
                  },
                }}
              >
                {document.title}
              </Text>
              {index === value && (
                <Slider
                  layoutId="underline"
                />
              )}
            </motion.button>
          ))}
        </Box>
      </Box>
      <Pager value={value}>
        {documents.map((document, index) => (
          <Box
            sx={{
              width: "100%",
              padding: "16px",
              borderTop: "1px solid",
              borderColor: "lightGrey",
              "&:last-child": {
                borderBottom: "1px solid",
                borderColor: "lightGrey",
              },
            }}
          >
            <Flex
              sx={{
                flexDirection: ["column", "column", "column"],
                alignItems: ["flex-start", "flex-start", "flex-start"],
              }}
            >
              <Box sx={{ marginBottom: [3, 3, 0] }}>
                {document.subtitle && (
                  <Heading as="h2" variant="h2" sx={{ width: "50%" }}>
                    {document.subtitle}
                  </Heading>
                )}
                {document.description && (
                  <Box>
                    <Text
                      as="div"
                      variant="sectionTitle"
                      sx={{ py: [4] }}
                      dangerouslySetInnerHTML={{ __html: document.description }}
                    />
                  </Box>
                )}
              </Box>
              <Box sx={{ pb:[6],"*": { mr: 4, "&:last-child": { mr: 0 } } }}>
                {document.documents.map(file => (
                  <i18nContext.Consumer>
                    {t => (
                      <Box sx={{ py: 1 }}>
                        <OutboundLink
                          href={file.url}
                          target="blank"
                          rel="noopener nofollow"
                        >
                          {file.title}.{file.format}
                        </OutboundLink>
                      </Box>
                    )}
                  </i18nContext.Consumer>
                ))}
              </Box>
            </Flex>
          </Box>
        ))}
      </Pager>
    </div>
  )
}

export default Tabs
