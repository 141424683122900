import React from "react"
import { graphql } from "gatsby"
import { Box, Container, Text, Heading, Flex } from "@theme-ui/components"
import loadable from "@loadable/component"
import Layout from "../components/layout"
import { getPagePath } from "../utils/path"
import { HelmetDatoCms } from "gatsby-source-datocms"
import TitleAndBody from "../components/blocks/titleAndBody"
import PageCarousel from "../components/blocks/pageCarousel"
import Image from "../components/blocks/image"
import ImageGallery from "../components/blocks/imageGallery"
import OrderedList from "../components/blocks/orderedList"
import ItemCarousel from "../components/blocks/itemCarousel"
import Accordion from "../components/blocks/accordion"
import DocumentCollection from "../components/blocks/documentCollection"
import Embed from "../components/blocks/embed"
import PageHero from "./pageHero"
import ImageAndText from "../components/blocks/imageAndText"
import NumbersGroup from "../components/blocks/numbersGroup"
import NumbersCollection from "../components/blocks/numbersCollections"
import ContactForm from "../components/blocks/contactFrom"
import ContactFooterSection from "../components/blocks/contactFooterSection"
import Categories from "../components/blocks/categoryBlock"
import { useFavicon } from "../hooks/useFavicon"
import TextAndImageWithSubtitleGrid from "../components/blocks/textAndImageWithSubtitleGrid"
import PartnerCollection from "../components/blocks/partnerCollection"
import FullWidthImageGrid from "../components/blocks/fullWidthImageGrid"

const LocationsMap = loadable(
  () => import("../components/blocks/locationMap"),
  { ssr: false }
)

const Page = ({ data: { page, site, contactFooter } }) => {
  const favicon = useFavicon().site.faviconMetaTags

  const pageAllSlugLocales = page._allSlugLocales.sort(function (a, b) {
    return site.locales.indexOf(a.locale) - site.locales.indexOf(b.locale)
  })

  const i18nPaths = pageAllSlugLocales.map(locale => {
    return {
      locale: locale.locale,
      value: getPagePath(page, locale.locale),
    }
  })

  return (
    <Layout locale={page.locale} i18nPaths={i18nPaths}>
      <HelmetDatoCms seo={page.seoMetaTags} favicon={favicon}>
        <html lang={page.locale} />
      </HelmetDatoCms>
      <PageHero page={page} image={page.heroImage} />
      {page.content.map(block => (
        <Box as="section" key={block.id}>
          {block.model.apiKey === "title_and_body" && (
            <TitleAndBody
              kicker={block.content.label}
              title={block.content.title}
              body={block.content.body}
            />
          )}
          {block.model.apiKey === "ordered_list" && (
            <OrderedList
              title={block.title}
              subtitle={block.subtitle}
              body={block.body}
            />
          )}
          {block.model.apiKey === "page_carousel" && (
            <PageCarousel title={block.title} pages={block.pages} />
          )}
          {block.model.apiKey === "item_carousel" && (
            <ItemCarousel items={block.items} />
          )}
          {block.model.apiKey === "accordion" && (
            <Accordion title={block.title} items={block.items} />
          )}
          {block.model.apiKey === "document_collection" && (
            <DocumentCollection
              image={block.image}
              documents={block.documents}
            />
          )}
          {block.model.apiKey === "image" && <Image image={block.image} />}
          {block.model.apiKey === "locations_map" && (
            <LocationsMap locations={block.locations} />
          )}
          {block.model.apiKey === "embed" && (
            <Embed
              title={block.title}
              code={block.code}
              fullWidth={block.fullWidth}
            />
          )}
          {block.model.apiKey === "image_gallery" && (
            <Container>
              <ImageGallery images={block.images} />
            </Container>
          )}
          {block.model.apiKey === "numbers_collection" && (
            <NumbersCollection
              title={block.title}
              image={block.image}
              numbers={block.numbers}
            />
          )}
          {block.model.apiKey === "contact_form" && (
            <ContactForm
              image={block.image}
              kicker={block.kicker}
              title={block.title}
              subtitle={block.subtitle}
              privacyPolicyDescription={block.privacyPolicyDescription}
              newsletterDescription={block.newsletterDescription}
            />
          )}
          {block.model.apiKey === "image_and_text" && (
            <ImageAndText
              kicker={block.content.label}
              title={block.content.title}
              richContent={block.content}
              image={block.content.image}
              rightAligned={block.rightAlignment}
              fullWidth={block.fullWidth}
              imageMargin={block.imageMargin}
            />
          )}
          {block.model.apiKey === "text_and_image_with_subtitle_grid" && (
            <TextAndImageWithSubtitleGrid
              image={block.image}
              contents={block.textImageRichcontent}
            />
          )}
          {block.model.apiKey === "category" && (
            <Categories
              page={page}
              title={block.title}
              description={block.description}
            />
          )}
          {block.model.apiKey === "partner_collection" && (
            <Container>
              <PartnerCollection partners={block.partners} />
            </Container>
          )}
          {block.model.apiKey === "full_width_image_grid" && (
            <FullWidthImageGrid items={block.items} />
          )}
        </Box>
      ))}
      {page.footerContactInfo && (
        <ContactFooterSection
          kicker={contactFooter.kicker}
          image={contactFooter.background}
          title={contactFooter.title}
          link={contactFooter.contactPageLink}
        />
      )}
    </Layout>
  )
}

export default Page

export const query = graphql`
  query PageQuery($id: String!, $locale: String!) {
    site: datoCmsSite {
      locales
    }
    page: datoCmsPage(id: { eq: $id }) {
      ...PageDetails
      ...PageTreeParent
      ...AllSlugLocales
      footerContactInfo
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      content {
        ... on DatoCmsNumbersCollection {
          id
          title
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          numbers {
            legend
            float
            suffix
            prefix
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsEmbed {
          id
          ...EmbedDetails
        }
        ... on DatoCmsTextAndImageWithSubtitleGrid {
          id
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          textImageRichcontent: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageAndText {
          id
          imageMargin
          rightAlignment
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImage {
          id
          image {
            gatsbyImageData(
              width: 1480
              placeholder: BLURRED
              forceBlurhash: false
            )
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsCategory {
          id
          title
          description
          model {
            apiKey
          }
        }
        ... on DatoCmsLocationsMap {
          id
          locations {
            originalId
            name
            body
            coordinates {
              latitude
              longitude
            }
            contacts {
              ... on DatoCmsGenericText {
                id
                description
                model {
                  apiKey
                }
              }
              ... on DatoCmsIconAndText {
                id
                content {
                  ... on DatoCmsRichContent {
                    ...RichContent
                  }
                }
                model {
                  apiKey
                }
              }
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPartnerCollection {
          id
          partners {
            id
            name
            url
            image {
              gatsbyImageData(placeholder: BLURRED, forceBlurhash: false)
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsFullWidthImageGrid {
          id
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsImageGallery {
          id
          ...ImageGallery
        }
        ... on DatoCmsDocumentCollection {
          id
          image {
            gatsbyImageData(
              width: 1920
              placeholder: NONE
              forceBlurhash: false
            )
          }
          documents {
            title
            subtitle
            description
            documents {
              url
              title
              format
            }
            meta {
              firstPublishedAt(locale: $locale, formatString: "DD MMMM Y")
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsAccordion {
          id
          title
          items: content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsTitleAndBody {
          id
          content {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsOrderedList {
          id
          title
          subtitle
          body {
            blocks
            links
            value
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsPageCarousel {
          id
          title
          pages {
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
          }
          model {
            apiKey
          }
        }
        ... on DatoCmsContactForm {
          id
          kicker
          title
          subtitle
          privacyPolicyDescription
          newsletterDescription
          model {
            apiKey
          }
        }
        ... on DatoCmsItemCarousel {
          id
          items {
            ... on DatoCmsRichContent {
              ...RichContent
            }
          }
          model {
            apiKey
          }
        }
      }
    }
    contactFooter: datoCmsContactFooterSection(locale: { eq: $locale }) {
      id
      kicker
      title
      model {
        apiKey
      }
      background {
        gatsbyImageData(
          width: 1920
          placeholder: NONE
          forceBlurhash: false
          imgixParams: {
            blendColor: "#212C30"
            blendMode: "multiply"
            blendAlpha: 70
          }
        )
      }
      contactPageLink {
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale

          model {
            apiKey
          }
          link {
            ... on DatoCmsBlogPage {
              ...BlogDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsArticle {
              ...ArticleDetails
              ...ArticleAllSlugLocales
            }
            ... on DatoCmsArticleCategory {
              ...ArticleCategoryDetails
              ...ArticleCategoryAllSlugLocales
            }
          }
        }
      }
    }
  }

  fragment RichContent on DatoCmsRichContent {
    title
    label
    subtitle
    viewMore {
      ... on DatoCmsExternalLink {
        anchor
        url
        model {
          apiKey
        }
      }
      ... on DatoCmsInternalLink {
        id: originalId
        anchor
        locale
        model {
          apiKey
        }
        link {
          ... on DatoCmsBlogPage {
            ...BlogDetails
          }
          ... on DatoCmsPage {
            ...PageDetails
            ...PageTreeParent
            ...AllSlugLocales
          }
          ... on DatoCmsArticle {
            ...ArticleDetails
            ...ArticleAllSlugLocales
          }
          ... on DatoCmsArticleCategory {
            ...ArticleCategoryDetails
            ...ArticleCategoryAllSlugLocales
          }
        }
      }
    }
    image {
      url
      gatsbyImageData(width: 1480, placeholder: BLURRED, forceBlurhash: false)
    }
    body {
      blocks {
        __typename
        ... on DatoCmsImageGallery {
          id: originalId
          ...ImageGallery
        }
        ... on DatoCmsEmbed {
          id: originalId
          ...EmbedDetails
        }
        ... on DatoCmsNumbersGroup {
          id: originalId
          numbers {
            legend
            float
            suffix
            prefix
          }
        }
      }
      links {
        __typename
        ... on DatoCmsInternalLink {
          id: originalId
          anchor
          locale
          model {
            apiKey
          }
          link {
            ... on DatoCmsBlogPage {
              ...BlogDetails
            }
            ... on DatoCmsPage {
              ...PageDetails
              ...PageTreeParent
              ...AllSlugLocales
            }
            ... on DatoCmsArticle {
              ...ArticleDetails
              ...ArticleAllSlugLocales
            }
            ... on DatoCmsArticleCategory {
              ...ArticleCategoryDetails
              ...ArticleCategoryAllSlugLocales
            }
          }
        }
      }
      value
    }
    model {
      apiKey
    }
  }

  fragment AllSlugLocales on DatoCmsPage {
    _allSlugLocales {
      value
      locale
    }
  }

  fragment PageDetails on DatoCmsPage {
    id
    locale
    title
    body {
      value
    }
    slug
    root
    model {
      apiKey
    }
    heroImage {
      gatsbyImageData(
        width: 1920
        placeholder: NONE
        forceBlurhash: false
        imgixParams: {
          blendColor: "#212C30"
          blendMode: "multiply"
          blendAlpha: 60
        }
      )
    }
  }

  fragment EmbedDetails on DatoCmsEmbed {
    title
    code
    fullWidth
    model {
      apiKey
    }
  }

  fragment PageTreeParent on DatoCmsPage {
    treeParent {
      id
      title
      slug
      root
      locale
      ...AllSlugLocales
      treeParent {
        id
        title
        slug
        root
        locale
        ...AllSlugLocales
      }
    }
  }
`
