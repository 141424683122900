import React, { useEffect, useState } from "react"
import ReactPaginate from "react-paginate"
import {
  Box,
  Text,
  Grid,
  Heading,
  Flex,
  Link,
  Container,
} from "@theme-ui/components"
import { i18nContext } from "../../context/i18nContext"
import Tabs from "../tabs"
import { GatsbyImage } from "gatsby-plugin-image"

const DocumentCollection = ({ image, documents }) => {
  const documentPerPage = 1
  const pageCount = Math.ceil(documents.length / documentPerPage)
  const [selectedPage, setSelectedPage] = useState(0)
  const [selectedDocuments, setSelectedDocuments] = useState(
    documents.slice(
      selectedPage * documentPerPage,
      selectedPage * documentPerPage + documentPerPage
    )
  )

  useEffect(() => {
    setSelectedDocuments(
      documents.slice(
        selectedPage * documentPerPage,
        selectedPage * documentPerPage + documentPerPage
      )
    )
  }, [selectedPage])

  function handleChange(data) {
    setSelectedPage(data.selected)
  }

  return (
    <Box sx={{ py:[6]}}>
      <Container variant="xl" sx={{backgroundColor :"lightGrey"}}>
        <Grid columns={[1, 1, "2fr 3fr"]} gap={32}>
          <Box sx={{
            ".gatsby-image-wrapper":{
              height: "100%"
            }
          }}>
            <GatsbyImage image={image.gatsbyImageData} />
          </Box>
          <Box>
            <Tabs documents={documents} />
          </Box>
        </Grid>
      </Container>
    </Box>
  )
}

export default DocumentCollection
