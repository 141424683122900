import React from "react"
import { Box, Grid, Heading, Container, Text } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"

const TitleAndBody = ({ title, body, kicker }) => {
  return (
    <Box sx={{ pt: [8] }}>
      <Container variant="xl">
        <Grid columns={[1, 1,  ".3fr .7fr" ]} gap={32} sx={{ px:[3,3,3,0]}}>
          <Box>
          {kicker && <Text variant="kicker">{kicker}</Text>}
            <Heading variant="h3" as="h3" sx={{ m: 0 }}>
              {title}
            </Heading>
          </Box>
          {body && (
            <Box>
              <RichContentStructuredText text={body} />
            </Box>
          )}
        </Grid>
      </Container>
    </Box>
  )
}

export default TitleAndBody
