import React from "react"
import { Box, Flex, Grid, Heading, Text, Container } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { GatsbyImage } from "gatsby-plugin-image"
import { number } from "prop-types"
import { MagicLink } from "../../utils/magicLink"
import { ChevronRight } from "react-feather"

const TextAndImageWithSubtitleGrid = ({ contents, image }) => {
  return (
    <Container variant="xl" sx={{pt:8 , px:[3,3]}}>
      <Box
          sx={{
            display:["block","block","none"],
            width:"100%",
            ".gatsby-image-wrapper": {
              maxHeight: "400px",
              width: ["100%", "100%", "100%", "100%"],
            },
          }}
        >
          <GatsbyImage image={image.gatsbyImageData} alt="" />
      </Box>
      <Grid columns={[1,2,3]} sx={{pt:[5,5,0]}}>
        {contents.map(richContent => {
          return <Item richContent={richContent} />
        })}
        <Box
          sx={{
            display:["none","none","block"],
            height:"100%",
            ".gatsby-image-wrapper": {
              height: ["100%", "100%", "100%", "100%"],
            },
          }}
        >
          <GatsbyImage image={image.gatsbyImageData} alt="" />
        </Box>
      </Grid>
    </Container>
  )
}

const Item = ({ richContent }) => (
  <Flex sx={{ flexDirection: "column", alignItems: "start" }}>
    <Box sx={{  "* ul li" : {
        pb: [4]
    } }}>
      <RichContentStructuredText text={richContent.body} />
    </Box>
    <Box sx={{ }}>
      {richContent.viewMore && (
        <MagicLink
          item={richContent.viewMore}
          variant="buttons.full.primaryArrow"
          customAnchor={
            <>
              <span>{richContent.viewMore.anchor}</span>
              <span>
                <ChevronRight />
              </span>
            </>
          }
        />
      )}
    </Box>
  </Flex>
)

export default TextAndImageWithSubtitleGrid
