import React, { useState } from "react"
import { Box, Grid, Heading, Container, Text, Flex } from "@theme-ui/components"
import RichContentStructuredText from "../richContentStructuredText"
import { StructuredText } from "react-datocms"
import { GatsbyImage } from "gatsby-plugin-image"
import { motion } from "framer-motion"
import { useThemeUI } from "theme-ui"
import { MagicLink } from "../../utils/magicLink"
import { Bold } from "react-feather"
import { ChevronRight } from "react-feather"
import { useResponsiveValue, useBreakpointIndex } from "@theme-ui/match-media"

const FullWidthImageGrid = ({ items }) => {
  return (
    <Box sx={{ my: 7 }}>
      <Grid
        sx={{ "grid-auto-rows": "1fr" }}
        columns={[1, "1fr 1fr ", "1fr 1fr ", "1fr 1fr 1fr"]}
        gap={0}
      >
        {items.map((item, index) => {
          return <Item item={item} index={index + 1} />
        })}
      </Grid>
    </Box>
  )
}

const Item = ({ active, item, index }) => {
  const context = useThemeUI()
  const { colors } = context.theme

  const [show, setShow] = useState(false)
  const breakpointIndex = useBreakpointIndex()

  return (
    <Box
      sx={{
        height: "100%",
        position: "relative",
        ".gatsby-image-wrapper": {
          height: "100%",
        },
      }}
    >
      <motion.div
        style={{
          zIndex: 2222,
          width: "100%",
          height: "100%",
          position: "absolute",
          display: "flex",
          alignItems: "flex-end",
        }}
        whileHover={{
          backgroundColor: colors.overlayPrimary,
        }}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
      >
        {(show || breakpointIndex === 0) && (
          <motion.div
            animate={{
              color: "white",
              opacity: 1,
            }}
            initial={{ opacity: 0 }}
          >
            <Box sx={{ p:[5],width: ["80%"], backgroundColor: "light" }}>
              <Heading
                sx={{
                  fontSize: [3],
                  fontWeight: "bold",
                  lineHeight: "1.5"
                }}
              >
                <Text sx={{color:"primary"}}>
                0{index}. 
                </Text> {item.title}
              </Heading>
              <RichContentStructuredText text={item.body} />
            </Box>
          </motion.div>
        )}
      </motion.div>

      <GatsbyImage image={item.image.gatsbyImageData} alt="" />
    </Box>
  )
}

export default FullWidthImageGrid
