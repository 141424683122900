import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Box, Grid, Link, Flex, Text } from "theme-ui"

const PartnerCollection = ({ partners }) => (
  <Grid columns={[2, 3, 4, 5]} gap={[0]}>
    {partners.map(partner => (
      <>
        {partner.url ? (
          <Link
            href={partner.url}
            target="blank"
            rel="noopener nofollow"
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              color: "dark",
              borderRight: "1px solid #efefef",
              padding: 4,
              "&:nth-child(4n-2)": {
                borderRight: ["none", "1px solid #efefef", "1px solid #efefef"],
              },
              "&:nth-child(6n)": {
                borderRight: ["none", "none", "1px solid #efefef"],
              },
              "&:nth-child(5n)": {
                borderRight: ["1px solid #efefef", "1px solid #efefef", "none"],
              },
              "&:nth-child(4n)": {
                borderRight: ["none", "1px solid #efefef", "1px solid #efefef"],
              },
              "&:nth-child(6n-3)": {
                borderRight: ["1px solid #efefef", "none", "1px solid #efefef"],
              },
            }}
          >
            <Flex
              sx={{
                mb: 4,
                height: "10rem",
                alignItems: "center",
                img: {
                  filter:
                    "url(\"data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg'><filter id='grayscale'><feColorMatrix type='matrix' values='0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0.3333 0.3333 0.3333 0 0 0 0 0 1 0'/></filter></svg>#grayscale\")",
                  "-webkit-filter": "grayscale(100%)",
                  "-moz-filter": "grayscale(100%)",
                  "-ms-filter": "grayscale(100%)",
                  filter: "grayscale(100%)",
                  filter: "gray",
                  transition: "filter 0.5s ease",
                  ":hover": {
                    "-webkit-filter": "none",
                    "-moz-filter": "none",
                    "-ms-filter": "none",
                    transition: "0.5s",
                    filter: "none"
                  }
                },
              }}
            >
              <GatsbyImage image={partner.image.gatsbyImageData} />
            </Flex>
          </Link>
        ) : (
          <Flex
            sx={{
              flexDirection: "column",
              alignItems: "center",
              color: "dark",
              borderRight: "1px solid #efefef",
              padding: 4,
              "&:nth-child(4n-2)": {
                borderRight: ["none", "1px solid #efefef", "1px solid #efefef"],
              },
              "&:nth-child(6n)": {
                borderRight: ["none", "none", "1px solid #efefef"],
              },
              "&:nth-child(4n)": {
                borderRight: ["none", "1px solid #efefef", "none"],
              },
              "&:nth-child(6n-3)": {
                borderRight: ["1px solid #efefef", "none", "1px solid #efefef"],
              },
            }}
          >
            <Flex sx={{ mb: 4, height: "10rem", alignItems: "center" }}>
              <GatsbyImage image={partner.image.gatsbyImageData} />
            </Flex>
          </Flex>
        )}
      </>
    ))}
  </Grid>
)

export default PartnerCollection
